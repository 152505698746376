import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerVendedor() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  },
  mostrarVendedor(id) {
    var url = ConfigAPI.baseURL + "sellers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarVendedor(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.post(url,params);
  },
  editarVendedor(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarVendedor(id) {
    var url = ConfigAPI.baseURL + "sellers/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  mostrarVendedorCalendario(id) {    
    var url = ConfigAPI.baseURL + "sellers/" + id + "/calendar" + Session.getToken();
    return instance.get(url);
  },
  filtrarVendedorCalendario(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/filter/query" + Session.getToken();
    return instance.post(url,params);
  },

  obtenerCalendarioBySellers(params) {
    var params = {
      sellers_id: params.sellers_id
    }
    var url = ConfigAPI.baseURL + "sellers-calendar/filter/bySellers" + Session.getToken();
    return instance.post(url,params);
  },
  agregarCalendario(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sellers-calendar" + Session.getToken();
    return instance.post(url,params);
  },
  editarCalendario(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sellers-calendar/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarCalendario(id) {
    var url = ConfigAPI.baseURL + "sellers-calendar/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerZona() {
    var url = ConfigAPI.baseURL + "sellers-zone" + Session.getToken();
    return instance.get(url);
  },  
  mostrarZona(id) {
    var url = ConfigAPI.baseURL + "sellers-zone/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarZona(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sellers-zone" + Session.getToken();
    return instance.post(url,params);
  },
  editarZona(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sellers-zone/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarZona(id) {
    var url = ConfigAPI.baseURL + "sellers-zone/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarZona(data, page) {
    var params = data
    var url = ConfigAPI.baseURL + "sellers-zone/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url, params);
  },

  mostrarClienteZona(id) {    
    var url = ConfigAPI.baseURL + "sellers-zone/" + id + "/customers" + Session.getToken();
    return instance.get(url);
  },
 
  obtenerZonaCustomers() {
    var url = ConfigAPI.baseURL + "sellers-zone-customers" + Session.getToken();
    return instance.get(url);
  },
  mostrarZonaCustomers(id) {
    var url = ConfigAPI.baseURL + "sellers-zone-customers/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarZonaCustomers(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "sellers-zone-customers" + Session.getToken();
    return instance.post(url,params);
  },
  editarZonaCustomers(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "sellers-zone-customers/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarZonaCustomers(id) {
    var url = ConfigAPI.baseURL + "sellers-zone-customers/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  
  obtenerClientesByZonas(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/filter/customerByZone" + Session.getToken();
    return instance.post(url,params);     
  },  

  obtenerReportsBySellers(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/query/reportsBySellers" + Session.getToken();
    return instance.post(url,params);
  }  
}

export default servicesAPI;
