<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Vendedores</h4>
            <div class="small text-muted">Panel de control de todos los vendedores</div>
          </b-col>
          <b-col>            
            <b-icon icon="question-circle-fill" class="h3 pull-right mt-2 module-help-information" v-b-popover.hover="help"></b-icon>            
          </b-col>                    
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row v-if="!isSeller">
            <b-col lg="4">              
              <Widget :configWidget="configWidget" reference="indicator_total_sellers" :hasAccess="configWidget.elements.indicatorTotalSellers" />                            
            </b-col>
            <b-col lg="8"></b-col>
          </b-row>
          <b-row v-if="isSeller">
            <b-col lg="12">              
              <Widget :configWidget="configWidget" reference="list_tasks" :hasAccess="configWidget.elements.listTasks" />
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">

          <b-list-group v-if="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openSellers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Vendedores</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración del legajo de cada vendedor
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="!isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openZones()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Zonas</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de las zonas disponibles
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCalendarSellers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Mi Calendario</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Planificación semanal
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="isSeller">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openCustomersSellers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Mis Clientes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Listado de todos mis clientes
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group v-if="isSeller && showReports">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openMyReports()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Mis Reportes</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Listado de todos mis reportes generados cronologicamente 
              </p>
            </b-list-group-item>
          </b-list-group>

        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.VENDEDORES,
          profile_id: Profiles.PERSONAL,
          elements: {             
            indicatorTotalSellers: true,
            listTasks: true,
          }
        },
        primaryColor: '',
        help: {
          title:'¿Cómo funciona este módulo?',
          content: () => { 
            return `Un usuario con perfil <b>STAFF</b>, puede crear nuevas zonas y asignarles cliente, 
                    crear nuevos vendedores y planificarle sus calendarios.
                    <br><br>
                    Un usuario con perfil <b>STAFF</b> y configurado como <b>VENDEDOR</b> 
                    puede visualizar su calendario y sus clientes segun las zonas asignadas.`
          },            
          html: true,
        }          
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.indicatorTotalSellers = Helper.hasAccessWidget(this.configWidget, 'indicator_total_sellers')
      this.configWidget.elements.listTasks = Helper.hasAccessWidget(this.configWidget, 'list_tasks')
      /* Fin configuracion */
    },
    mounted() {    
      this.setup()
    }, 
    computed: {
      isSeller(){
        if(Helper.getSeller()) {
          return true
        } else {
          return false
        }
      },
      sellerObject() {
        return Helper.getSeller();
      },
      showReports() {
        var modulesActive = Session.getSession().auth.user.permissions.modules
        var status = false

        modulesActive.forEach(element => {
          if(element.id == Modules.INFORME_VISITAS ||
             element.id == Modules.INFORME_COBRANZAS ||
             element.id == Modules.INFORME_NOVEDADES ||
             element.id == Modules.INFORME_GENERAL) {
              status = true
          }             
        })        

        return status
      }
    },    
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openSellers() {
        this.$router.push({ name: 'SellersStaffCrudSellers' })
      }, 
      openZones() {
        this.$router.push({ name: 'SellersStaffCrudZones' })
      },       
      openCalendarSellers(){
        this.$router.push({ name: 'SellersStaffListCalendar' })
      },
      openCustomersSellers(){
        this.$router.push({ name: 'SellersStaffListCustomers' })
      },
      openMyReports() {
        if(this.sellerObject) {
          this.$router.push({ name: 'SellersStaffSellersReports', params: {sellersID: this.sellerObject.id } })
        }        
      }
    }    
   
  }
</script>

<style>

</style>
